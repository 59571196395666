import classNames from 'classnames';
import { ComponentProps, forwardRef, ReactNode } from 'react';
import styles from './ReactionButton.module.scss';

export type ReactionIconSize = '24' | '20' | '16';
type Props = ComponentProps<'button'> & {
  isActive?: boolean;
  icon?: ReactNode;
  iconSize?: ReactionIconSize;
};

const ReactionButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, icon, isActive, iconSize = '20', ...rest }: Props, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(styles.button, {
          [styles.active]: isActive,
          [styles.hasIcon]: !!icon,
          [styles.hasText]: !!children,
        })}
        {...rest}
      >
        {icon && (
          <span
            className={classNames('block', styles[`iconSize-${iconSize}`], {
              'mr-1': !!children,
            })}
          >
            {icon}
          </span>
        )}
        {children}
      </button>
    );
  }
);

ReactionButton.displayName = 'ReactionButtons';

export default ReactionButton;
