import { PureQueryOptions } from '@apollo/client';
import ShelfForm, { ShelfFormData } from 'components/forms/ShelfForm';
import { shelfLoader } from 'dataloaders/shelfLoader';
import {
  AddBookToShelfMutationVariables,
  BookCountsByProfileIdDocument,
  BookPartsFragment,
  DiscoveriesByBookDocument,
  DiscoveryActorType,
  GetBooksOnShelfCountsDocument,
  GetShelvesByProfileIdDocument,
  MyBookCountsDocument,
  ShelfPartsFragment,
  useAddBookToShelfMutation,
  useCreateShelfMutation,
} from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import useDiscoveryContext from 'hooks/useDiscoveryContext';
import useMixpanel from 'hooks/useMixpanel';
import { useRouter } from 'next/router';
import { useState } from 'react';
import Modal from 'ui/specific/modal/Modal';
import getApolloClient from 'utils/getApolloClient';
import { routes } from 'utils/routes';

type Props = {
  isOpen: boolean;
  setOpen: (state: boolean) => void;
  pushToShelfPage?: boolean;
  book?: BookPartsFragment;
};

// add book prop to add book automatically to the shelf

const CreateShelfModal = ({ isOpen, setOpen, pushToShelfPage, book }: Props): JSX.Element | null => {
  const mixpanel = useMixpanel();
  const client = getApolloClient();
  const [createShelfMutation] = useCreateShelfMutation();
  const [addBookToShelf, { loading: addBookLoading }] = useAddBookToShelfMutation();
  const [isDirty, setIsDirty] = useState(false);
  const router = useRouter();
  const { profile } = useAuthContext();
  const discoveryContext = useDiscoveryContext();

  if (!profile) return null;

  const refetchQueries: Array<PureQueryOptions> = [
    {
      query: GetShelvesByProfileIdDocument,
      variables: { profileId: profile.id, offset: 0, limit: 20 },
    },
    {
      query: GetBooksOnShelfCountsDocument,
      variables: { profileId: profile?.id },
    },
    {
      query: MyBookCountsDocument,
    },
  ];

  const addToShelf = async (shelf: ShelfPartsFragment, book: BookPartsFragment) => {
    if (!profile || !book) return;

    if (addBookLoading) return;
    const variables: AddBookToShelfMutationVariables = {
      bookId: book.id,
      shelfId: shelf.id,
    };
    // Discovery creation:
    if (discoveryContext.mediatorHandle) {
      variables.mediatorHandle = discoveryContext.mediatorHandle;
      variables.mediatorType = DiscoveryActorType.Profile;
    }
    await addBookToShelf({
      awaitRefetchQueries: true,
      variables,
      optimisticResponse: {
        addBookToShelf: {
          ...shelf,
          owner: profile,
        },
      },
      refetchQueries: () => {
        const queries = [...refetchQueries];
        if (discoveryContext.mediatorId)
          queries.push({
            query: DiscoveriesByBookDocument,
            variables: {
              actorId: discoveryContext.mediatorId,
              bookId: book.id,
            },
          });
        return queries;
      },
    });

    mixpanel.trackEvent('book_added_to_shelf', {
      shelfId: shelf.id,
      isbn13: book.isbn13,
    });

    await shelfLoader.load({ profileId: profile.id, bookId: book.id });
  };

  const handleSubmit = async (data: ShelfFormData) => {
    const shelf = await createShelfMutation({
      awaitRefetchQueries: true,
      variables: {
        title: data.title,
        description: data.description,
      },
      refetchQueries: [
        {
          query: BookCountsByProfileIdDocument,
          variables: { profileId: profile?.id },
        },
        {
          query: GetBooksOnShelfCountsDocument,
          variables: { profileId: profile?.id },
        },
        { query: GetShelvesByProfileIdDocument, variables: { profileId: profile?.id, limit: 20, offset: 0 } },
      ],
    });
    mixpanel.trackEvent('shelf_created', {
      shelfId: shelf.data?.createShelf?.id,
    });
    if (shelf && pushToShelfPage) {
      router.push(routes.shelf, `${profile?.handle}/shelf/${shelf?.data?.createShelf?.slug}`);
    }
    if (shelf?.data?.createShelf && book) {
      const newShelf = shelf.data.createShelf;
      await addToShelf(newShelf, book);
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getMyBookCollections' });
    }
    setOpen(false);
  };

  if (!isOpen) return null;

  return (
    <Modal
      close={() => {
        if (!isDirty) return setOpen(false);
        const areYouSure = confirm('You have unsaved changes. Are you sure you want to leave?');
        if (areYouSure) {
          setOpen(false);
        }
      }}
    >
      <ShelfForm onSubmit={handleSubmit} setIsDirty={setIsDirty} />
    </Modal>
  );
};

export default CreateShelfModal;
