import { useReadingProgressLoader } from 'dataloaders/readingProgressLoader';
import { BookPartsFragment, ReadingProgressUnit } from 'generated/graphql';
import { useMemo, useState } from 'react';
import ButtonSimple from 'ui/generic/ButtonSimple';
import { getProgressPercentage } from 'utils/readingProgress';
import styles from './ReadingProgress.module.scss';
import ReadingProgressDisplay from './ReadingProgressDisplay';
import { ReadingProgressForm } from './ReadingProgressForm';

type Props = {
  book: BookPartsFragment;
  editMode?: boolean;
};

export const ReadingProgress = ({ book, editMode = false }: Props): JSX.Element => {
  const { data: progress, refetch: refetchDataLoader } = useReadingProgressLoader(book.id);
  const [showForm, setShowForm] = useState(editMode);

  const progressPercentage = useMemo(() => {
    if (progress?.progress === 0 && progress.unit === ReadingProgressUnit.TimeLeft) return 100;
    return progress && progress.capacity && progress.progress ? getProgressPercentage(progress) : 0;
  }, [progress]);

  return (
    <div
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {!progress && !showForm && (
        <ButtonSimple
          className="w-full h-full justify-center"
          variant="fadedUnderline"
          onClick={() => setShowForm(true)}
        >
          Add reading progress...
        </ButtonSimple>
      )}
      {showForm && (
        <ReadingProgressForm
          refetch={refetchDataLoader}
          initialData={progress || undefined}
          book={book}
          closeMe={() => setShowForm(false)}
          readingStateId={progress?.id || null}
        />
      )}
      {progress && !showForm && (
        <div className={styles.progressView} onClick={() => setShowForm(true)}>
          <span className={styles.progress}>
            <ReadingProgressDisplay readingProgress={progress} />
          </span>
          <span>
            <ButtonSimple variant="faded" onClick={() => setShowForm(true)}>
              Update progress
            </ButtonSimple>
          </span>
        </div>
      )}
      <div style={{ width: `${progressPercentage}%` }} className={styles.readingProgressBar} />
    </div>
  );
};

export default ReadingProgress;
