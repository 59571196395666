import classNames from 'classnames';
import { ReadingStatus } from 'generated/graphql';
import { ComponentProps, forwardRef } from 'react';
import { MoreIcon } from 'ui/icons';
import styles from './DropdownButton.module.scss';

const dropdownButtonText = {
  [ReadingStatus.WantsToRead]: 'Want to read',
  [ReadingStatus.IsReading]: 'Currently reading',
  [ReadingStatus.Dropped]: 'Did not finish',
  [ReadingStatus.Finished]: 'Finished',
  [ReadingStatus.None]: '',
};

type DropdownButtonProps = {
  status?: ReadingStatus;
  mini?: boolean;
} & ComponentProps<'button'>;

const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ onClick, status, mini }: DropdownButtonProps, ref) => {
    const hasStatus = status && status !== ReadingStatus.None;

    return (
      <button
        role="dropdown-button"
        ref={ref}
        onClick={onClick}
        className={classNames(styles.dropdownButton, {
          [styles.dropdownButtonInverse]: hasStatus,
          [styles.mini]: mini,
        })}
      >
        {hasStatus && status && <span className={styles.inverseInner}>{dropdownButtonText[status]}</span>}
        {!hasStatus ? (
          <MoreIcon />
        ) : (
          <svg
            width="15"
            height="9"
            viewBox="0 0 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="var(--uiWhite)"
          >
            <path d="M1 1L7.5 7.5L14 1" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        )}
      </button>
    );
  }
);

DropdownButton.displayName = 'DropdownButton';

export default DropdownButton;
