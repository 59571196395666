import classnames from 'classnames';
import {
  BookPartsFragment,
  ReadingProgressPartsFragment,
  ReadingProgressUnit,
  useUpdateReadingProgressMutation,
} from 'generated/graphql';
import useMixpanel from 'hooks/useMixpanel';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Input } from 'ui/generic';
import { ButtonSimple } from 'ui/generic/ButtonSimple';
import { CheckIcon, RemoveIcon } from 'ui/icons';
import {
  convertSecondsToDisplayString,
  durationConvertor,
  getUnitProgressData,
  unitProgressData,
} from 'utils/readingProgress';
import styles from './ReadingProgressForm.module.scss';
import { ReadingProgressFormData } from './types';
import { unitOptions } from './unitOptions';

type Props = {
  closeMe: () => void;
  initialData?: ReadingProgressPartsFragment;
  book: BookPartsFragment;
  refetch: () => void;
  readingStateId: string | null;
};

export const ReadingProgressForm = ({
  closeMe,
  book,
  initialData,
  refetch,
  readingStateId,
}: Props): JSX.Element => {
  const { trackEvent } = useMixpanel();
  const [selectWidth, setSelectWidth] = useState('38px');
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useForm<ReadingProgressFormData>({
    defaultValues: {
      bookId: book.id,
      readingProgressId: initialData?.id,
      progress: initialData?.progress ? initialData.progress : undefined,
      capacity: initialData?.capacity,
      unit: initialData?.unit || ReadingProgressUnit.Page,
      stringCapacity:
        (initialData?.capacity && convertSecondsToDisplayString(initialData?.capacity)) || undefined,
      stringProgress:
        (initialData?.progress && convertSecondsToDisplayString(initialData?.progress)) || undefined,
    },
  });
  const [submitReadingProgress] = useUpdateReadingProgressMutation();
  const progressValue = watch('progress');
  const capacityValue = watch('capacity');
  const stringProgressValue = watch('stringProgress');
  const stringCapacityValue = watch('stringCapacity');
  const unit = watch('unit');

  const maxProgress = useMemo(() => {
    if (unit === ReadingProgressUnit.Percent) return 100;
    if (capacityValue) return capacityValue;
    return 99999;
  }, [capacityValue, unit]);

  useEffect(() => {
    if (!initialData) return;
    setValue('unit', initialData.unit);
    setValue('capacity', initialData.capacity || null);
    setValue('progress', initialData.progress || undefined);
    setValue('readingProgressId', initialData.id);
    setValue('unit', initialData.unit);
    setValue('bookId', book.id);
    setValue(
      'stringCapacity',
      (initialData.capacity && convertSecondsToDisplayString(initialData?.capacity)) || undefined
    );
    setValue(
      'stringProgress',
      (initialData.progress && convertSecondsToDisplayString(initialData?.progress)) || undefined
    );
  }, [initialData]);

  useEffect(() => {
    if (unit === ReadingProgressUnit.Page) {
      setSelectWidth('44px');
    } else if (unit === ReadingProgressUnit.Percent) {
      setSelectWidth('85px');
      setValue('capacity', 100);
    } else if (unit === ReadingProgressUnit.TimeIn) {
      setSelectWidth('60px');
    } else {
      setSelectWidth('70px');
    }
    return () => {
      setSelectWidth('43px');
    };
  }, [unit]);

  useEffect(() => {
    // predicted unit and capacity for select formats
    if (initialData) return;
    if (book.physicalFormat === 'ebook') {
      setValue('unit', ReadingProgressUnit.Percent);
    }
    if (book.physicalFormat === 'audio') {
      setValue('unit', ReadingProgressUnit.TimeLeft);
    }
    if (book.pageCount) {
      setValue('capacity', book.pageCount);
    }
  }, [book, unit, initialData]);

  const handleUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const unit = e.target.value as ReadingProgressUnit;
    const unitValue = getUnitProgressData(unit);
    setValue('unit', unitValue);
  };

  async function onSubmit(data: ReadingProgressFormData) {
    if (isSubmitting) return;
    let progress = Number(data.progress);
    let capacity = Number(data.capacity);

    if (isNaN(progress) && stringProgressValue) {
      progress = durationConvertor(stringProgressValue.replace(/[^\d:]/g, '0'));
    }
    if (isNaN(capacity) && stringCapacityValue) {
      capacity = durationConvertor(stringCapacityValue);
    }

    const unit = data.unit;
    const bookId = data.bookId;

    if (!progress) {
      progress = 0;
    }
    await submitReadingProgress({
      variables: { bookId, unit, progress, capacity, readingStateId },
    });
    await refetch();
    trackEvent('update_reading_progress', {
      isbn13: book.isbn13,
      progressType: unitProgressData[unit],
    });
    closeMe();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formHolder}>
      <div className={styles.selectHolder}>
        <select
          className={styles.select}
          onChange={(e) => handleUnitChange(e)}
          value={unitProgressData[unit]}
          style={{ width: selectWidth }}
        >
          {unitOptions.map((y) => (
            <option key={y.value} value={y.value}>
              {y.title}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.inputs}>
        <span className={styles.inputWrap}>
          <span className={styles.widthMachine} aria-hidden="true">
            {unit === ReadingProgressUnit.TimeIn || unit === ReadingProgressUnit.TimeLeft
              ? (!!stringProgressValue && stringProgressValue + ' :') || 'hh:mm'
              : progressValue || 12}
          </span>
          {unit === ReadingProgressUnit.TimeIn || unit === ReadingProgressUnit.TimeLeft ? (
            <Controller
              name="progress"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref } }) => (
                <NumberFormat
                  ref={ref}
                  value={convertSecondsToDisplayString(value)}
                  isNumericString
                  name={name}
                  className={styles.durationInput}
                  placeholder="hh:mm"
                  format="##:##"
                  mask={['h', 'h', 'm', 'm']}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const stringValue = e.target.value.length > 0 ? e.target.value : 'hh:mm';
                    setValue('stringProgress', stringValue);
                    const newValue = durationConvertor(e.target.value);
                    onChange(newValue);
                  }} // send value to hook form
                  autoFocus={initialData?.unit === unit}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    setTimeout(() => {
                      e.target.select();
                    }, 100);
                  }}
                />
              )}
            />
          ) : (
            <Input
              className={styles.input}
              id="progress"
              {...register('progress', { required: 'Progress is required' })}
              placeholder="12"
              type="number"
              pattern={'[0-9]*'}
              inputMode={'numeric'}
              step="1"
              min={0}
              max={maxProgress}
              style={{ border: 'none', height: '100%' }}
              autoFocus={initialData?.unit === unit}
              onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                setTimeout(() => {
                  e.target.select();
                }, 100);
              }}
            />
          )}
        </span>
        {unit !== ReadingProgressUnit.Percent && (
          <>
            <span className="mx-2">of</span>
            <span className={classnames(styles.inputWrap, styles.capacityInputWrap)}>
              <span className={styles.widthMachine} aria-hidden="true">
                {unit === (ReadingProgressUnit.TimeIn || ReadingProgressUnit.TimeLeft)
                  ? stringCapacityValue + ' ' || 'hh:mm'
                  : capacityValue || 12}
              </span>

              {unit === ReadingProgressUnit.TimeIn || unit === ReadingProgressUnit.TimeLeft ? (
                <Controller
                  name="capacity"
                  control={control}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <NumberFormat
                      isNumericString
                      ref={ref}
                      name={name}
                      value={value ? convertSecondsToDisplayString(value) : undefined}
                      className={styles.durationInput}
                      placeholder="hh:mm"
                      format="##:##"
                      mask={['h', 'h', 'm', 'm']}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const stringValue = e.target.value.length > 0 ? e.target.value : 'hh:mm';
                        setValue('stringCapacity', stringValue);
                        const newValue = durationConvertor(e.target.value);
                        onChange(newValue);
                      }} // send value to hook form
                      onFocus={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setTimeout(() => {
                          event.target.setSelectionRange(0, event.target.value.length);
                        }, 50);
                      }}
                    />
                  )}
                />
              ) : (
                <Input
                  step="1"
                  className={styles.input}
                  id="capacity"
                  {...register('capacity')}
                  placeholder="eg. 354"
                  type="number"
                  pattern={'[0-9]*'}
                  inputMode={'numeric'}
                  min={0}
                  max={99999}
                  style={{ border: 'none', height: '100%' }}
                  onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                    setTimeout(() => {
                      e.target.select();
                    }, 50);
                  }}
                />
              )}
            </span>
          </>
        )}
      </div>
      <div className={styles.buttons}>
        <ButtonSimple variant="accent" iconOnly type="submit" onClick={() => handleSubmit}>
          <CheckIcon />
        </ButtonSimple>
        <ButtonSimple onClick={closeMe} variant="faded" iconOnly>
          <RemoveIcon />
        </ButtonSimple>
      </div>
    </form>
  );
};
