import { ReadingProgressPartsFragment, ReadingProgressUnit } from 'generated/graphql';

export const unitProgressData = {
  [ReadingProgressUnit.Page]: 'Page',
  [ReadingProgressUnit.TimeIn]: 'Time in',
  [ReadingProgressUnit.TimeLeft]: 'Time left',
  [ReadingProgressUnit.Percent]: 'Progress',
};

export const getUnitProgressData = (unit: string): ReadingProgressUnit => {
  if (unit === 'Page') return ReadingProgressUnit.Page;
  if (unit === 'Time in') return ReadingProgressUnit.TimeIn;
  if (unit === 'Time left') return ReadingProgressUnit.TimeLeft;
  if (unit === 'Progress') return ReadingProgressUnit.Percent;
  throw new Error('Unknown unit');
};

export function durationConvertor(value: string): number {
  if (!value) return 0;
  const valueArray = value.split(':');
  const hours = Number(valueArray[0]);
  const minutes = Number(valueArray[1]);
  const totalSeconds = hours * 3600 + minutes * 60;
  return totalSeconds;
}

export function convertSecondsToDisplayString(seconds?: number, humanReadable?: boolean): string | null {
  if (!seconds) return null;
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const normalizeHours = () => {
    if (h === 0) return '00';
    if (h < 10) {
      return `0${h}`;
    } else {
      return `${h}`;
    }
  };
  const normalizeMinutes = () => {
    if (m === 0) return '00';
    if (m < 10) {
      return `0${m}`;
    } else {
      return `${m}`;
    }
  };
  const humanHours = h > 0 ? `${h}h` : '';
  let humanMins = m > 0 ? `${m}m` : '';
  if (humanHours === '' && humanMins === '') {
    humanMins = '0m';
  }
  if (humanReadable) return `${humanHours}${humanMins}`;
  return `${normalizeHours()}${normalizeMinutes()}`;
}

export function getProgressPercentage(progress: ReadingProgressPartsFragment): number {
  if (!progress.capacity || !progress.progress) return 0;
  const percentage =
    progress && progress.capacity && progress.progress ? (progress.progress * 100) / progress.capacity : 0;

  return progress.unit === ReadingProgressUnit.TimeLeft ? 100 - percentage : percentage;
}
