import FormError from 'components/forms/FormError';
import { ReactNode, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'ui/generic';
import { noop } from 'utils/noop';
import styles from './ShelfForm.module.scss';

export type ShelfFormData = {
  title: string;
  description: string;
};

type Props = {
  onSubmit: (data: ShelfFormData) => void;
  initialData?: ShelfFormData;
  additionalButtons?: ReactNode;
  setIsDirty?: (val: boolean) => void;
};

const CreateShelf = ({ onSubmit, initialData, additionalButtons, setIsDirty = noop }: Props): JSX.Element => {
  const { register, handleSubmit, formState } = useForm<ShelfFormData>({
    defaultValues: initialData,
  });

  const { errors } = formState;

  useEffect(() => {
    setIsDirty(formState.isDirty);
  }, [formState.isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      <h2 className="displayHeader">{initialData ? 'Edit shelf' : 'Create a shelf'}</h2>
      <label htmlFor="title">Name</label>
      <input
        id="title"
        {...register('title', { required: 'Name is required' })}
        placeholder="Give it a name"
        className="text-input"
      />
      <label htmlFor="description">Description (optional)</label>
      <textarea
        id="description"
        {...register('description')}
        placeholder="Add a description"
        className={`text-input ${styles.textarea}`}
      />
      <div className={styles.controls}>
        <Button disabled={formState.isSubmitting} type="submit">
          {initialData ? 'Save shelf' : 'Create shelf'}
        </Button>
        <span>{additionalButtons}</span>
      </div>
      {errors && Object.keys(errors).length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <FormError errors={errors} />
        </div>
      )}
    </form>
  );
};

export default CreateShelf;
