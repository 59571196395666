import DataLoader from 'dataloader';
import { ReviewsDocument, ReviewsQueryVariables, ReviewsQuery, ReviewPartsFragment } from 'generated/graphql';
import { useEffect, useState } from 'react';
import getApolloClient from 'utils/getApolloClient';

type Pair = {
  bookId: string;
  profileId: string;
};

export const reviewLoader = new DataLoader(
  async (keys: readonly Pair[]): Promise<Array<ReviewPartsFragment | null>> => {
    const client = getApolloClient();

    const { data } = await client.query<ReviewsQuery, ReviewsQueryVariables>({
      query: ReviewsDocument,
      variables: { pairs: [...keys] },
      fetchPolicy: 'no-cache',
    });

    return data.reviews;
  },
  {
    batch: true,
    cache: true,
    cacheKeyFn: (key: Pair): string => `review:${key.bookId}:${key.profileId}`,
  }
);

export function useReviewLoader({ profileId, bookId }: Partial<Pair>) {
  const [data, setData] = useState<ReviewPartsFragment | null>();

  useEffect(() => {
    if (!profileId || !bookId) return;
    reviewLoader.load({ profileId, bookId }).then(setData);
  }, [profileId, bookId]);

  return {
    data,
    setData,
    refetch: async () => {
      if (!profileId || !bookId) return;
      reviewLoader.clear({ profileId, bookId });
      const data = await reviewLoader.load({ profileId, bookId });
      setData(data);
    },
  };
}
