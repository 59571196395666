import { PureQueryOptions } from '@apollo/client';
import LoadingSpinner from 'components/basics/LoadingSpinner';
import DropdownSearchBar from 'components/layout/dropdownSearch/DropdownSearchBar';
import { useShelfLoader } from 'dataloaders/shelfLoader';
import {
  BookCollectionRelationPartsFragment,
  BookPartsFragment,
  BooksInGoalDocument,
  DiscoveryActorType,
  GetBooksOnShelfCountsDocument,
  GetClubBooksDocument,
  MyBookCountsDocument,
  ProfilePartsFragment,
  useGetMyBookCollectionsQuery,
  useUpdateBookCollectionRelationMutation,
} from 'generated/graphql';
import useDiscoveryContext from 'hooks/useDiscoveryContext';
import useMixpanel from 'hooks/useMixpanel';
import { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { VisualCheckbox } from 'ui/generic';
import { ClubIcon, GoalIcon, ShelfIcon } from 'ui/icons';
import { ActionItem } from 'ui/specific/actionList';

const LIMIT = 10;

interface BookCollectionOptionsProps {
  book: BookPartsFragment;
  profile: ProfilePartsFragment;
}

export const BookCollectionActionItems = ({
  book,
  profile,
}: BookCollectionOptionsProps): JSX.Element | null => {
  const trackUpdatedItem = useTrackUpdates(book);
  const [query, setQuery] = useState('');
  const { data, loading, fetchMore } = useGetMyBookCollectionsQuery({
    variables: {
      bookId: book.id,
      profileId: profile.id,
      limit: LIMIT,
      offset: 0,
      query,
    },
  });
  const collections = data?.getMyBookCollections;

  const { refetch: refetchShelfDataLoader } = useShelfLoader({ profileId: profile.id, bookId: book.id });
  const discoveryContext = useDiscoveryContext();
  const [updateBookCollectionRelation] = useUpdateBookCollectionRelationMutation();

  const handleClick = async (item: BookCollectionRelationPartsFragment) => {
    const discovery = discoveryContext.mediatorHandle
      ? {
          mediatorHandle: discoveryContext.mediatorHandle,
          mediatorType: DiscoveryActorType.Profile,
        }
      : {};

    const updateItem = { ...item, isInCollection: !item.isInCollection };
    await updateBookCollectionRelation({
      variables: { ...updateItem, bookId: book.id, ...discovery },
      refetchQueries: refetchQueries(updateItem, profile.id),
      optimisticResponse: { updateBookCollectionRelation: updateItem },
    });
    trackUpdatedItem(updateItem);
    if (item.type === 'shelf') {
      refetchShelfDataLoader();
    }
  };

  return (
    <>
      <div className="mb-1">
        <DropdownSearchBar
          handleSubmit={setQuery}
          searchFor={'collections'}
          handleClear={() => setQuery('')}
          preventAutoFocus
        />
      </div>
      {loading && <ActionItem item={{ render: <LoadingSpinner /> }} />}
      {collections && collections.length > 0 ? (
        <>
          {collections.map((item) => (
            <ActionItem
              key={item.id}
              item={{
                icon:
                  item.type === 'shelf' ? (
                    <ShelfIcon />
                  ) : item.type === 'goalParticipant' ? (
                    <GoalIcon />
                  ) : (
                    <ClubIcon />
                  ),
                title: item.name,
                subtitle: <VisualCheckbox isChecked={item.isInCollection} />,
                onClick: () => handleClick(item),
              }}
            />
          ))}
          <Waypoint
            onEnter={() => {
              if (!data || loading) return;
              fetchMore({
                variables: {
                  offset: collections.length,
                  limit: LIMIT,
                },
              });
            }}
          />
        </>
      ) : (
        <>
          {!loading && (
            <ActionItem
              item={{ render: <div className="ml-4 flex items-center textDiscreet">Nothing here...</div> }}
            />
          )}
        </>
      )}
    </>
  );
};

const refetchQueries = (
  item: BookCollectionRelationPartsFragment,
  profileId: string
): Array<PureQueryOptions> => {
  if (item.type === 'shelf') {
    return [
      { query: GetBooksOnShelfCountsDocument, variables: { profileId } },
      { query: MyBookCountsDocument },
    ];
  } else if (item.type === 'club') {
    return [{ query: GetClubBooksDocument, variables: { clubId: item.id, limit: 20, offset: 0 } }];
  } else if (item.type === 'goalParticipant') {
    return [{ query: BooksInGoalDocument, variables: { participantId: item.id, limit: 20, offset: 0 } }];
  }
  return [];
};

const useTrackUpdates = (book: BookPartsFragment) => {
  const mixpanel = useMixpanel();

  const trackUpdatedItem = (updatedItem: BookCollectionRelationPartsFragment) => {
    if (updatedItem.isInCollection) {
      if (updatedItem.type === 'shelf') {
        mixpanel.trackEvent('book_added_to_shelf', { shelfId: updatedItem.id, isbn13: book.isbn13 });
      } else if (updatedItem.type === 'goalParticipant') {
        mixpanel.trackEvent('book_added_to_goal');
      } else if (updatedItem.type === 'club') {
        mixpanel.trackEvent('book_added_to_club', { clubId: updatedItem.id });
      }
    } else {
      if (updatedItem.type === 'shelf') {
        mixpanel.trackEvent('book_removed_from_shelf', { shelfId: updatedItem.id, isbn13: book.isbn13 });
      } else if (updatedItem.type === 'goalParticipant') {
        mixpanel.trackEvent('book_removed_from_goal');
      } else if (updatedItem.type === 'club') {
        mixpanel.trackEvent('book_removed_from_club', { clubId: updatedItem.id });
      }
    }
  };

  return trackUpdatedItem;
};
