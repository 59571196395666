import { useReviewLoader } from 'dataloaders/reviewLoader';
import { BookPartsFragment } from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAuthContext } from 'hooks/useAuth';
import React, { useMemo } from 'react';
import { HighlightIcon, StarEmptyIcon, StarHalfIcon } from 'ui/icons';
import { ActionItem, ActionItemType } from 'ui/specific/actionList';
import { scrollElementIntoView } from 'utils/scrollElementIntoView';

interface BookModalActionItemProps {
  book: BookPartsFragment;
}

export const BookModalActionItems = ({ book }: BookModalActionItemProps): JSX.Element => {
  const { setMomentModal, setReviewModal } = useGlobalModalContext();
  const { profile } = useAuthContext();
  const { data: review } = useReviewLoader({ profileId: profile?.id, bookId: book.id });

  const items: ActionItemType[] = useMemo(
    () => [
      {
        title: 'Add highlight',
        onClick: (_, e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          setMomentModal({ book });
        },
        icon: <HighlightIcon />,
      },
      {
        title: review ? 'Edit review' : 'Review',
        onClick: (_, e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          setReviewModal({
            book,
            afterCreate: () => {
              setTimeout(() => {
                scrollElementIntoView('bookReviews');
              }, 300);
            },
          });
        },
        icon: review ? <StarHalfIcon /> : <StarEmptyIcon />,
        subtitle: review && review.rating ? `${review.rating}/5` : undefined,
      },
    ],
    [book, review]
  );

  return (
    <>
      {items.map((item, index) => (
        <ActionItem key={index} item={item} />
      ))}
    </>
  );
};
