import { ProfilePartsFragment } from 'generated/graphql';
import { useScreenSize } from 'hooks/useScreenSize';
import { Avatar } from 'ui/generic';
import styles from './UserGroup.module.scss';

type Props = {
  avatarSize?: 21 | 34 | 40;
  users: Array<ProfilePartsFragment>;
  extraUserCount?: number | null;
};

const UserGroup = ({ avatarSize = 40, users, extraUserCount }: Props): JSX.Element => {
  const { smScreen } = useScreenSize();
  // when avatar size is same as tags we want it same size as tags on small screens
  const size = smScreen && avatarSize === 34 ? 30 : avatarSize;
  const avatarMargin = size * 0.25 * -1;
  const containerWidth =
    (users.length + (extraUserCount ? 1 : 0)) * (avatarSize + 2) - (users.length - 1) * avatarMargin * -1;
  return (
    <div style={{ width: containerWidth }} className={styles.avatars}>
      {users.map((user, i) => {
        if (!users) return null;
        return (
          <div
            key={user.id}
            style={i !== 0 ? { width: size, marginLeft: avatarMargin } : { width: size }}
            className={styles.avatarHolder}
          >
            <Avatar profile={user} width={size} />
          </div>
        );
      })}
      {extraUserCount && (
        <div className={styles.avatarHolder}>
          <span
            style={{ minWidth: size - 8, height: size, marginLeft: avatarMargin }}
            className={styles.userCount}
          >
            +{extraUserCount}
          </span>
        </div>
      )}
    </div>
  );
};

export default UserGroup;
