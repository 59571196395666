import DotsButton from 'components/book/options/DotsButton';
import DropdownButton from 'components/book/options/DropdownButton';
import OptionsMenu from 'components/book/options/OptionsMenu';
import OptionsButton from 'components/reaction/OptionsButton';
import CreateShelfModal from 'containers/CreateShelfModal';
import { BookPartsFragment } from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAuthContext } from 'hooks/useAuth';
import { useMyReadingStateByBook } from 'hooks/useReadingStates';
import { useScreenSize } from 'hooks/useScreenSize';
import { ReactElement, useEffect, useState } from 'react';
import { ButtonSimple } from 'ui/generic';
import { AddIcon, LeftIcon, MyBookDataIcon, PlusIcon, RightIcon } from 'ui/icons';
import { ActionItem, ActionItemType, ActionList } from 'ui/specific/actionList';
import { BookCollectionActionItems } from './BookCollectionActionItems';
import { BookModalActionItems } from './BookModalActionItems';
import ReadingProgress from './ReadingProgress/ReadingProgress';
import { ReadingStateActionItems } from './ReadingStateActionItems';

type OptionsButtonStyle = 'dots' | 'arrow' | 'dots-vertical' | 'none';

type Props = {
  book: BookPartsFragment;
  positioningStrategy?: 'absolute' | 'fixed';
  buttonStyle?: OptionsButtonStyle;
  additionalOptions?: ActionItemType[];
  zLayer?: 'Dropdown' | 'Header' | 'Modal';
  mini?: boolean;
  isOpen?: boolean;
  setOpen?: (newVal: boolean) => void;
};

export const BookItemOptions = ({
  book,
  positioningStrategy,
  additionalOptions,
  buttonStyle = 'dots',
  zLayer,
  mini,
  isOpen,
  setOpen,
}: Props): JSX.Element | null => {
  const { setPersonalBookDetailsModal } = useGlobalModalContext();
  const [isCreateShelfModalOpen, setCreateShelfModalOpen] = useState(false);
  const [showBookCollections, setBookCollections] = useState(false);
  const { isSignedIn, profile } = useAuthContext();
  const { smScreen } = useScreenSize();
  const readingState = useMyReadingStateByBook({ bookId: book.id, workId: book.workId });

  useEffect(() => {
    if (smScreen) {
      setBookCollections(false);
    }
  }, [smScreen]);

  if (!isSignedIn || !profile) return null;

  const addedOptions: ActionItemType[] = additionalOptions ? [{ divider: 'full' }, ...additionalOptions] : [];

  return (
    <>
      <OptionsMenu
        progressArrowColor={readingState?.status === 'IS_READING' && !showBookCollections}
        button={renderButton(buttonStyle, mini)}
        positioningStrategy={positioningStrategy}
        status={readingState?.status}
        zLayer={zLayer}
        onClose={() => setBookCollections(false)}
        isOpen={isOpen}
        setOpen={setOpen}
      >
        {!showBookCollections && readingState?.status === 'IS_READING' && <ReadingProgress book={book} />}
        {!showBookCollections && (
          <ActionList>
            <ReadingStateActionItems book={book} />

            <ActionItem
              item={{
                title: 'Add to...',
                onClick: () => setBookCollections(true),
                icon: <AddIcon />,
                subtitle: <RightIcon />,
                variant: 'faded',
              }}
            />

            <ActionItem item={{ divider: 'semi' }} />
            <BookModalActionItems book={book} />

            <ActionItem item={{ divider: 'full' }} />
            <ActionItem
              item={{
                icon: <MyBookDataIcon />,
                title: 'Edition & read dates',
                onClick: () => setPersonalBookDetailsModal(book.id),
              }}
            />
            {addedOptions.map((item, index) => (
              <ActionItem key={index} item={item} />
            ))}
          </ActionList>
        )}
        {showBookCollections && (
          <div onClick={(e) => e.stopPropagation()}>
            <ActionItem
              item={{
                onClick: () => setBookCollections(false),
                variant: 'faded',
                title: 'Back',
                icon: <LeftIcon />,
                subtitle: setOpen ? (
                  <ButtonSimple variant="accent" onClick={() => setOpen(false)}>
                    Done
                  </ButtonSimple>
                ) : undefined,
              }}
            />
            <ActionItem item={{ divider: 'full', spacing: 'none' }} />
            <ActionList maxHeight={!smScreen ? 348 : undefined}>
              <BookCollectionActionItems book={book} profile={profile} />
              <ActionItem item={{ divider: 'semi' }} />
              <ActionItem
                item={{
                  title: 'Create shelf',
                  icon: <PlusIcon />,
                  onClick: () => setCreateShelfModalOpen(true),
                }}
              />
            </ActionList>
          </div>
        )}
      </OptionsMenu>
      {isCreateShelfModalOpen && (
        <CreateShelfModal book={book} isOpen={isCreateShelfModalOpen} setOpen={setCreateShelfModalOpen} />
      )}
    </>
  );
};

const renderButton = (buttonStyle: OptionsButtonStyle, mini?: boolean): ReactElement | null => {
  if (buttonStyle === 'arrow') {
    return <DropdownButton mini={mini} />;
  }
  if (buttonStyle === 'dots-vertical') {
    return (
      <div role="dropdown-button">
        <OptionsButton />
      </div>
    );
  }
  if (buttonStyle === 'none') return null;
  return <DotsButton />;
};

export default BookItemOptions;
