export const unitOptions = [
  {
    title: 'Page',
    value: 'Page',
  },
  {
    title: 'Time left',
    value: 'Time left',
  },
  {
    title: 'Time in',
    value: 'Time in',
  },
  {
    title: 'Progress %',
    value: 'Progress',
  },
];
