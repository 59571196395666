import { forwardRef } from 'react';
import ReactionButton, { ReactionIconSize } from './ReactionButton';

type Props = {
  iconSize?: ReactionIconSize;
};

const OptionsButton = forwardRef<HTMLButtonElement, Props>(({ iconSize }, ref): JSX.Element => {
  return (
    <ReactionButton
      ref={ref}
      iconSize={iconSize}
      icon={
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="20" r="2" transform="rotate(-90 12 20)" />
          <circle cx="12" cy="12" r="2" transform="rotate(-90 12 12)" />
          <circle cx="12" cy="4" r="2" transform="rotate(-90 12 4)" />
        </svg>
      }
    />
  );
});

OptionsButton.displayName = 'OptionsButton';

export default OptionsButton;
