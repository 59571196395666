import { FieldErrors } from 'react-hook-form';
import { LinkText } from 'ui/generic';
import styles from './FormError.module.scss';

const FormError = ({ errors }: { errors: FieldErrors }): JSX.Element | null => {
  if (Object.values(errors || []).length === 0) return null;

  return (
    <div className={styles.error}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 19H4.70985C3.94502 19 3.46331 18.1763 3.83827 17.5097L11.1284 4.54947C11.5107 3.86982 12.4893 3.86982 12.8716 4.54947L20.1617 17.5097C20.5367 18.1763 20.055 19 19.2902 19H12Z"
          stroke="#E14D4D"
          strokeWidth="1.6"
          strokeLinecap="round"
        />
        <path
          d="M12.7162 8.95862C12.7162 8.54338 12.3796 8.20676 11.9644 8.20676C11.5491 8.20676 11.2125 8.54338 11.2125 8.95863V13.3845C11.2125 13.7997 11.5491 14.1364 11.9644 14.1364C12.3796 14.1364 12.7162 13.7997 12.7162 13.3845V8.95862ZM11.9496 17.0796C12.4418 17.0796 12.8445 16.6769 12.8445 16.1847C12.8445 15.6925 12.4418 15.2898 11.9496 15.2898C11.4574 15.2898 11.0547 15.6925 11.0547 16.1847C11.0547 16.6769 11.4574 17.0796 11.9496 17.0796Z"
          fill="#E14D4D"
        />
      </svg>
      <div>
        {Object.values(errors).map((error) => {
          if (!error?.message) return null;
          return (
            <div className="mt-2 mb-2" key={error.message}>
              {error.message && <LinkText>{error.message}</LinkText>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormError;
