import { ReadingProgressPartsFragment, ReadingProgressUnit } from 'generated/graphql';
import { BaseHTMLAttributes } from 'react';
import { convertSecondsToDisplayString, unitProgressData } from 'utils/readingProgress';

interface Props extends BaseHTMLAttributes<HTMLSpanElement> {
  readingProgress: ReadingProgressPartsFragment;
  shortForm?: boolean; // if true displays likeL 4h3m left
}

export const ReadingProgressDisplay = ({ readingProgress, shortForm, ...rest }: Props): JSX.Element => {
  const unitText = unitProgressData[readingProgress?.unit];
  const progressDisplay =
    readingProgress.progress &&
    (readingProgress?.unit === ReadingProgressUnit.TimeIn ||
      readingProgress?.unit === ReadingProgressUnit.TimeLeft)
      ? convertSecondsToDisplayString(readingProgress?.progress, true)
      : readingProgress.progress;

  if (shortForm)
    return (
      <span {...rest}>
        {readingProgress.unit === ReadingProgressUnit.Page && 'Page '}
        {progressDisplay}
        {readingProgress.unit === ReadingProgressUnit.Percent && '%'}
        {readingProgress.unit === ReadingProgressUnit.TimeIn && ' in'}
        {readingProgress.unit === ReadingProgressUnit.TimeLeft && ' left'}
      </span>
    );
  return (
    <>
      {unitText} {progressDisplay}
      {readingProgress.unit === ReadingProgressUnit.Percent && '%'}
    </>
  );
};

export default ReadingProgressDisplay;
