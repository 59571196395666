import { forwardRef, ComponentProps } from 'react';
import classNames from 'classnames';

import styles from './DotsButton.module.scss';

type DotsButtonProps = {
  isActive?: boolean;
} & ComponentProps<'button'>;

const DotsButton = forwardRef<HTMLButtonElement, DotsButtonProps>(
  ({ isActive, ...rest }: DotsButtonProps, ref) => {
    return (
      <button
        {...rest}
        className={classNames(styles.dotsButton, {
          [styles.active]: isActive,
        })}
        ref={ref}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#E9E9E5" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="20" r="2" transform="rotate(-90 12 20)" />
          <circle cx="12" cy="12" r="2" transform="rotate(-90 12 12)" />
          <circle cx="12" cy="4" r="2" transform="rotate(-90 12 4)" />
        </svg>
      </button>
    );
  }
);

DotsButton.displayName = 'DotsButton';

export default DotsButton;
